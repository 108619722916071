import React, { useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";

import { TextInput } from "components/UI/Forms"

import "./ProjectForm.scss";

const ProjectForm = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    let project = {
      name: name,
      description: description
    }

    props.handleSubmit(project);
  };

  const validateForm = () => {
    return  name;
  };

  return (
    <div className="project-form-component text-center">
      <Form onSubmit={handleSubmit}>
        <div className="input-table">
          <TextInput
            id="name"
            label="Name"
            value={name}
            onChange={(event) => { setName(event.target.value) }}
          />
          <TextInput
            id="description"
            label="Description"
            value={description}
            optional={true}
            onChange={(event) => { setDescription(event.target.value) }}
          />
        </div>

        <div className="required-field-text">
          <span className="asterisk">*</span> Required Field
        </div>

        <FormGroup controlId="buttons">
          <Button
            disabled={!validateForm() || props.loading}
            type="submit"
          >
            Submit
          </Button>
          { props.cancelForm &&
            <Button
              className="btn-secondary"
              onClick={props.cancelForm}
            >
              Cancel
            </Button>
          }
        </FormGroup>

      </Form>

    </div>
  );
};

export default ProjectForm;
