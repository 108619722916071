import React from "react";
import { Route, Routes } from "react-router-dom";

import About from "./components/About";
import ForgotPassword from "./components/Users/ForgotPassword";
import Login from "./components/Auth/Login";
import NotFound from "./components/NotFound";
import Project from "./components/Project";
import Projects from "./components/Projects";
import Register from "./components/Users/Register";
import ResetPassword from "./components/Users/ResetPassword";
import UserProfile from "./components/Users/UserProfile";

const AppRoutes = () =>
  <Routes>
    <Route path="/" exact element={ <Projects/> } />
    <Route path="/about" exact element={ <About/> } />
    <Route path="/projects" exact element={ <Projects/> } />
    <Route path="/projects/:uuid" exact element={ <Project/> }/>

    <Route path="/login" exact element={ <Login/> } />
    <Route path="/forgot-password" exact element={ <ForgotPassword/> } />
    <Route path="/register" exact element={ <Register/> } />
    <Route path="/reset-password" exact element={ <ResetPassword/> } />
    <Route path="/user-profile" exact element={ <UserProfile/> } />
    <Route element={NotFound} />
  </Routes>;

export default AppRoutes;
