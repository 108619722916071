import React, { useEffect, useRef, useState } from "react";
import colormap from "colormap";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal } from "@fortawesome/free-solid-svg-icons";

import Timeline from "wavesurfer.js/dist/plugins/timeline.esm.js";
import Spectrogram from "wavesurfer.js/dist/plugins/spectrogram.esm.js"

// import Timeline from "https://unpkg.com/wavesurfer.js@7/dist/plugins/timeline.esm.js"
// import Spectrogram from "https://unpkg.com/wavesurfer.js@7/dist/plugins/spectrogram.esm.js"

const Waveform = ({
  fileLocation,
  setDuration,
  waveformRef,
  progressBarRef
}) => {
  const containerRef = useRef();
  const specRef = useRef();

  const [showSpectrogram, setShowSpectrogram] = useState(false);

  useEffect(() => {
    const colors = colormap({
      // colormap: "hot",
      nshades: 256,
      format: "float"
    });

    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      // barWidth: 2,
      // barHeight: 10,
      // cursorWidth: 0,
      url: fileLocation,
      normalize: true,
      plugins: [
        Timeline.create(),
        Spectrogram.create({
          colorMap: colors,
          container: specRef.current,
          labels: true,
          height: 200,
          // splitChannels: true,
        })
      ]
    });

    waveSurfer.on("ready", () => {
      waveformRef.current = waveSurfer;
      let durationInSeconds = waveformRef.current.getDuration();
      setDuration(durationInSeconds);
      progressBarRef.current.max = durationInSeconds;
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [fileLocation, progressBarRef, setDuration, specRef, waveformRef]);

  return (
    <>
      <div ref={containerRef} />
        <div
          ref={specRef}
          className={`${showSpectrogram ? "" : "d-none"}`}
        />

      <FontAwesomeIcon
        className="link"
        icon={faSignal}
        size="lg"
        onClick={() => setShowSpectrogram(!showSpectrogram)}
      />
    </>
  );
};

Waveform.propTypes = {
  fileLocation: PropTypes.any.isRequired,
  setDuration: PropTypes.any.isRequired,
  waveformRef: PropTypes.any.isRequired,
  progressBarRef: PropTypes.any.isRequired,
};

export default Waveform;