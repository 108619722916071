import React from "react";
import ReactDOM from 'react-dom/client';

import App from "./App";
import { ContextOneProvider } from "./contexts/ContextOne";
import { ToasterContextProvider } from "./contexts/ToasterContext";
import * as serviceWorker from "./serviceWorker";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ContextOneProvider>
      <ToasterContextProvider>
        <App />
      </ToasterContextProvider>
    </ContextOneProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
