import React from "react";
import { BrowserRouter } from "react-router-dom";

import Header from "./components/Header";
import Routes from "./Routes";
import Toast from "./components/UI/Toast";

import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">

        <Toast />
        <Header/>

        <div className="body-background"></div>

        <div className="main-container">
          <div className="container main-container-2">
            <Routes />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;