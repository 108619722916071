import React from "react";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import Select from "react-select";
import TimePicker from "rc-time-picker";

const LabelSection = (props) => {
  return (
    <div>
      <div>
        {props.label}
        {
          !props.optional && <span className="asterisk">&nbsp;*</span>
        }
      </div>
      <div className="description" style={{ height: props.showDescription ? 72 : 0 }}>
        {props.description}
      </div>
    </div>
  )
};

export const DatePickerInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <LabelSection {...props} />
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <DatePicker
            className="datepicker"
            selected={props.selected}
            onChange={props.onChange}
            minDate={props.minDate}
            disabled={props.disabled}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const FileInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <LabelSection {...props} />
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <Form.Control
            type="file"
            onChange={props.onChange}
            onBlur={props.onBlur}
            as={props.as}
            disabled={props.disabled}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const MultiSelectInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <LabelSection {...props} />
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <Select
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: 'black',
              }),
            }}
            isMulti
            value={props.disabled ? "" : props.value}
            onChange={props.onChange}
            options={props.options}
            disabled={props.disabled}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const NumberInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <LabelSection {...props} />
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <Form.Control
            type="number"
            value={props.disabled ? "" : props.value}
            onChange={props.onChange}
            disabled={props.disabled}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const SelectInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <LabelSection {...props} />
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <Select
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: 'black',
              }),
            }}
            value={props.disabled ? "" : props.value}
            onChange={props.onChange}
            options={props.options}
            disabled={props.disabled}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const TextInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <LabelSection {...props} />
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <Form.Control
            value={props.disabled ? "" : props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            as={props.as}
            disabled={props.disabled}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const TimePickerInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        {props.label}
        {
          !props.optional && <span className="asterisk">&nbsp;*</span>
        }
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <TimePicker
            className="timePicker"
            use12Hours
            showSecond={props.showSecond}
            value={props.disabled ? "" : props.value}
            onChange={props.onChange}
            minuteStep={props.minuteStep}
            disabled={props.disabled}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const ToggleInput = (props) => {
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <LabelSection {...props} />
      </div>
      <div className="col-12 col-sm-6">
        <Form.Group className="form-group" controlId={props.id}>
          <label className="switch" htmlFor={`${props.id}-checkbox`}>
            <input
              type="checkbox"
              id={`${props.id}-checkbox`}
              checked={props.checked}
              onChange={props.onChange}
              disabled={props.disabled}
            />
            <div className="slider round"></div>
          </label>
        </Form.Group>
      </div>
    </div>
  );
};
