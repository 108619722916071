import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck, faDownload, faMusic, faTrash } from "@fortawesome/free-solid-svg-icons";

import ApiService from "services/ApiService";
import AudioPlayer from "components/AudioPlayer/AudioPlayer";
import { dateLocalDisplay, titleize } from "lib/Utils";
import { f0MethodForValue } from "services/EnumService";

import "./InferenceRow.scss";


const InferenceRow = ({
  handleArchiveInference,
  handleCancelInference,
  inference
}) => {
  const [inferenceUrl, setInferenceUrl] = useState();
  const [myInference, setMyInference] = useState(inference);
  const [showAudio, setShowAudio] = useState(false);

  // Status Fetching

  useEffect(() => {
    if (myInference.processing_status === "queued" || myInference.processing_status === "processing") {
      const interval = setInterval(async () => {
          const inf = await ApiService.getInference(myInference.uuid);

          if (inf.processing_status !== "queued" && inf.processing_status !== "processing") {
            setMyInference(inf);
            clearInterval(interval);
          }
      }, 5000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Inference Action Handling

  const handleDownloadInference = async () => {
    let tmpUrl = inferenceUrl;
    console.log(tmpUrl)
    console.log(inferenceUrl)

    if (!inferenceUrl) {
      tmpUrl = await ApiService.getInferenceUrl(myInference.uuid);
      setInferenceUrl(tmpUrl);
    }

    console.log(tmpUrl);

    fetch(tmpUrl, {
      method: "GET",
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        myInference.filename,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  const handleAudioToggle = async () => {
    if (!inferenceUrl) {
      const url = await ApiService.getInferenceUrl(myInference.uuid);
      setInferenceUrl(url);
    }

    setShowAudio(!showAudio);
  };

  // Helpers

  // const formantShiftingDisplay = () => {
  //   if (!!myInference?.formant_shifting) {
  //     return `${myInference.quefrency}, ${myInference.quefrency.timbre}`;
  //   }
  // };

  const showArchiveIcon = () => {
    return (
      myInference.processing_status !== "queued" && 
      myInference.processing_status !== "processing"
    );
  };

  const showCancelIcon = () => {
    return (myInference.processing_status === "queued");
  };

  const showDownloadIcon = () => {
    return (myInference.processing_status === "complete");
  };

  const showAudioPlayerIcon = () => {
    return (myInference.processing_status === "complete");
  };

  return (
    <React.Fragment key={myInference.uuid}>
      <tr className="inference-row">
        <td>
          <div>
            {myInference.source_performance?.song_title}
          </div>
          <div>
            {myInference.source_performance?.vocal_performer}
          </div>
          <div className="description">
            {myInference.source_performance?.description}
          </div>
          <div className="description">
            {myInference.source_performance?.original_file_name}
          </div>
        </td>
        <td>
          <div>
            {myInference.ai_model?.artist}
          </div>
          <div className="description">
            {myInference.ai_model?.description}
          </div>
        </td>
        <td>{myInference.description}</td>
        <td>{myInference.transposition}</td>
        <td>
          <b>Pitch</b>: {f0MethodForValue(myInference.f0_method)}
          {
            myInference.f0_method === "mangio-crepe" &&
            <span>
              <br/>
              <b>Crepe HL</b>: {myInference.crepe_hop_length}
            </span>
          }
          {
            myInference.f0_method === "harvest" &&
            <span>
              <br/>
              <b>Harvest MFR</b>: {myInference.harvest_median_filter_radius}
             </span>
          }
        </td>
        <td>
          <b>MVE</b>: {myInference.mix_volume_envelope}
          <br/>
          <b>FIR</b>: {myInference.feature_index_ratio}
          <br/>
          <b>VCP</b>: {myInference.voiceless_consonant_protection}
        </td>
        <td>
          {
            myInference.formant_shifting &&
            <>
              <b>Quefrency</b>: {myInference.quefrency}
              <br/>
              <b>Timbre</b>: {myInference.timbre}
            </>
          }
        </td>
        <td>
          {
            myInference.use_noise_filter &&
            <FontAwesomeIcon
              icon={faCheck}
              size="lg"
            />
          }
        </td>
        <td>{titleize(myInference.processing_status)}</td>
        <td>{dateLocalDisplay(myInference.created_at)}</td>
        <td className="action-cell">
          {
            showDownloadIcon() &&
            <FontAwesomeIcon
              icon={faDownload}
              size="lg"
              className="link text-primary"
              onClick={() => handleDownloadInference()}
              title="Download"
            />
          }
          {
            showAudioPlayerIcon() &&
            <FontAwesomeIcon
              icon={faMusic}
              size="lg"
              className="link text-primary"
              onClick={() => handleAudioToggle()}
              title="Audio Player"
            />
          }
          {
            showCancelIcon() &&
            <FontAwesomeIcon
              icon={faBan}
              size="lg"
              className="link text-danger"
              onClick={() => handleCancelInference(myInference)}
              title="Cancel"
            />
          }
          {
            showArchiveIcon() &&
            <>
              <hr style={{margin: "6px"}}/>
              <FontAwesomeIcon
                icon={faTrash}
                size="lg"
                className="link text-danger"
                onClick={() => handleArchiveInference(myInference)}
                title="Remove"
              />
            </>
          }

        </td>
      </tr>
      <tr>
        { showAudio && inferenceUrl &&
          <td colSpan={11}>
            <AudioPlayer
              fileLocation={inferenceUrl}
            />
          </td>
        }
      </tr>
    </React.Fragment>
  );
};

InferenceRow.propTypes = {
  handleArchiveInference: PropTypes.func.isRequired,
  handleCancelInference:PropTypes.func.isRequired,
  inference: PropTypes.any.isRequired,
};

export default InferenceRow;
