import AuthService from "./AuthService";

export default class ApiService {
  static getAiModelsForProject  = async (project_uuid) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/projects/${project_uuid}/ai_models`);
  };

  static getInference = async (uuid) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/inferences/${uuid}`);
  };

  static getInferencesForProject = async (project_uuid) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/projects/${project_uuid}/inferences`);
  };

  static getInferenceUrl = async (uuid) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/inferences/${uuid}/url`);
  };

  static getOrganizations = async () => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/organizations`);
  };

  static getOrganizationUsers = async (organizationId) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/organizations/${organizationId}/users`);
  };

  static getProject = async (uuid) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/projects/${uuid}`);
  };

  static getProjects = async () => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/projects`);
  };

  static getSourcePerformancesForProject = async (project_uuid) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/projects/${project_uuid}/source_performances`);
  };

  static getUser = async (uuid) => {
    return await AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${uuid}`);
  };
}
