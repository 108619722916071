import React, { useContext, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";
import Spinner from "components/UI/Spinner";
import { ToasterContext } from "contexts/ToasterContext";

import "./ResetPassword.scss";

const ResetPassword = (props) => {
  const { state: { loading }, dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Form Values
  const [password, setPassword] = useState("");

  const resetPasswordToken = searchParams.get('reset_password_token');

  const validateForm = () => {
    return password.length > 0;
  };

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({ type: "loading", val: true });

    AuthService.fetchNotLoggedIn(`${process.env.REACT_APP_API_ENDPOINT}/user/reset_password`, {
      method: "POST",
      body: JSON.stringify({
        token: resetPasswordToken,
        password: password
      })
    })
    .then((result) => {
      dispatch({ type: "loading", val: false });
      AddToast("success", null, 'Password has been reset.');
      navigate("/login");
    })
    .catch((fetchError) => {
      dispatch({ type: "loading", val: false });
      AddToast("danger", null, fetchError.message);
    })
  };

  return (
    <div className="reset-password-component text-center">
      <Spinner show={loading} />

      <h2>Reset Password</h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-group" controlId="password">
          <Form.Control
            value={password}
            onChange={handleChange}
            type="password"
            placeholder="new password"
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="form-group" controlId="buttons">
          <Button
            disabled={!validateForm() || loading}
            type="submit"
          >
            Reset
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default ResetPassword;
