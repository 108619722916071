import React from "react";

import "./NotFound.scss";

const NotFound = () =>
  <div className="not-found-component">
    <h3>Sorry, page not found.</h3>
  </div>;

export default NotFound;
