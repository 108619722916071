import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { ToasterContext } from "contexts/ToasterContext";

import "./Toast.scss";

const Toast = props => {
  const {
    autoDelete,
    dismissTime,
    position,
  } = props;

  const { state, RemoveToast } = useContext(ToasterContext);

  // const [list, setList] = useState(toastList);
  const [toastDismiss, setToastDismiss] = useState([]);

  // The useCallback is used so as to prevent unncessary renders
  const deleteToast = useCallback(id => {
    RemoveToast(id);

    // After deleting a toast, clear the contents of the array
    toastDismiss.length = 0;

    // Update the toastDismiss array with the empty array
    setToastDismiss([...toastDismiss]);
  }, [toastDismiss, RemoveToast]);

  useEffect(() => {
    // wait for some seconds before adding a toast to toastDismiss array
    setTimeout(() => {
      setToastDismiss(state.list);
    }, dismissTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.list]);

  useEffect(() => {
    // We only want the newly added toast inside the array. So we reverse to get the toast at index 0
    const toast = toastDismiss.reverse();

    if (autoDelete && toast.length) {
      const idToRemove = toast[0].id;

      setTimeout(() => {
        deleteToast(idToRemove);
      }, dismissTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastDismiss]);

  return (
    <>
      <div className={`notification-container ${position}`}>
      {
        state.list.map((toast, i) =>
          <div
            key={i}
            className={`notification toste ${position}`}
            style={{ backgroundColor: toast.backgroundColor }}
          >
            <button onClick={() => deleteToast(toast.id)}>
              X
            </button>
            <div className="notification-image">
              <img src={toast.icon} alt="" />
            </div>
            <div>
              <p className="notification-title">{toast.title}</p>
              <p className="notification-message">
                {toast.description}
              </p>
            </div>
          </div>
          )
      }
      </div>
    </>
  );
}

Toast.defaultProps = {
  position: "top-center",
  dismissTime: 2000,
  autoDelete: true
}

Toast.propTypes = {
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number
}

export default Toast;

// https://blog.logrocket.com/how-to-create-a-custom-toast-component-with-react/
// https://github.com/uzochukwueddie/react-toast
