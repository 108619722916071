import React, { useContext, useEffect, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlusCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import AiModelForm from "components/AiModels/AiModelForm";
import ApiService from "services/ApiService";
import AuthService from "services/AuthService";
import { booleans, f0MethodForValue, f0Methods } from "services/EnumService";
import { ContextOne } from "contexts/ContextOne";
import { dateLocalDisplay, dictValueChosen, getDictValues } from "lib/Utils";
import SourcePerformanceForm from "components/SourcePerformances/SourcePerformanceForm";
import { MultiSelectInput, TextInput } from "components/UI/Forms"
import Modal from "components/UI/Modal"
import { ToasterContext } from "contexts/ToasterContext";

import "./InferenceForm.scss";


const InferenceForm = (props) => {
  const { state: { loading }, dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const [sourcePerformance, setSourcePerformance] = useState(null);
  const [aiModel, setAiModel] = useState(null);

  const [transposition, setTransposition] = useState("0");
  const [f0Method, setF0Method] = useState([]);
  const [crepeHopLength, setCrepeHopLength] = useState("128");
  const [harvestMedianFilterRadius, setHarvestMedianFilterRadius] = useState("3");
  const [mixVolumeEnvelope, setMixVolumeEnvelope] = useState("0.25");
  const [featureIndexRatio, setFeatureIndexRatio] = useState("0.75");
  const [voicelessConsonantProtection, setVoicelessConsonantProtection] = useState("0.33");
  const [formantShifting, setFormantShifting] = useState([{value: "false", label: "No"}]);
  const [quefrency, setQuefrency] = useState("8");
  const [timbre, setTimbre] = useState("1.2");
  const [useNoiseFilter, setUseNoiseFilter] = useState([{value: "false", label: "No"}]);
  const [description, setDescription] = useState("");

  const [aiModels, setAiModels] = useState(null);
  const [sourcePerformances, setSourcePerformances] = useState(null);

  const [showAiModelForm, setShowAiModelForm] = useState(false);
  const [showSourcePerformanceForm, setShowSourcePerformanceForm] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  // Data Fetching

  const fetchAiModels = async () => {
    const data = await ApiService.getAiModelsForProject(props.project_uuid);
    setAiModels(data);
  };

  const fetchSourcePerformances = async () => {
    const data = await ApiService.getSourcePerformancesForProject(props.project_uuid);
    setSourcePerformances(data);
  };

  const fetchData = async () => {
    dispatch({ type: "loading", val: true });

    await fetchAiModels();
    await fetchSourcePerformances();

    dispatch({ type: "loading", val: false });
  };

  // Inference Creation

  const handleInferenceSubmit = (event) => {
    event.preventDefault();

    let inference = {
      transposition: transposition,
      f0Method: f0Method.map(getDictValues),
      crepeHopLength: crepeHopLength,
      harvestMedianFilterRadius: harvestMedianFilterRadius,
      mixVolumeEnvelope: mixVolumeEnvelope,
      featureIndexRatio: featureIndexRatio,
      voicelessConsonantProtection: voicelessConsonantProtection,
      formantShifting: formantShifting.map(getDictValues),
      quefrency: quefrency,
      timbre: timbre,
      useNoiseFilter: useNoiseFilter.map(getDictValues),
      description: description,
      aiModelUuid: aiModel,
      sourcePerformanceUuid: sourcePerformance
    }

    props.handleSubmit(inference);
  };

  const validateInferenceForm = () => {
    return  transposition.length &&

            f0Method &&
            f0Method.length &&
            (!dictValueChosen(f0Method, "mangio-crepe") || crepeHopLength.length) &&
            (!dictValueChosen(f0Method, "harvest") || harvestMedianFilterRadius.length) &&

            mixVolumeEnvelope.length &&
            featureIndexRatio.length &&
            voicelessConsonantProtection.length &&

            formantShifting &&
            formantShifting.length &&
            (!dictValueChosen(formantShifting, "true") || quefrency.length) &&
            (!dictValueChosen(formantShifting, "true") || timbre.length) &&

            useNoiseFilter != null &&
            useNoiseFilter.length &&
            aiModel &&
            sourcePerformance;
  };

  // AI Model

  const handleAiModelSubmit = async (aiModel) => {
    const data = new FormData();
    data.append("artist", aiModel.artist);
    data.append("description", aiModel.description);
    data.append("pth_file", aiModel.pthFile);
    if (aiModel.indexFile) {
      data.append("index_file", aiModel.indexFile);
    }

    const options = {
      method: "POST",
      body: data
    };

    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //   },
    // };

    dispatch({ type: "loading", val: true });

    AuthService.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/ai_models/upload`,
      options,
      {}
    )
    .then(
      async (result) => {
        await fetchAiModels();
        AddToast("success", "AI Model Added", null);
        return;
      },
      (error) => {
        AddToast("danger", null, error);
        return;
      }
    )
    .then(() => {
      dispatch({ type: "loading", val: false });
      setShowAiModelForm(false);
    })
  };

  const handleAiModelShowModal = (event) => {
    event.preventDefault();

    setShowAiModelForm(true);
  };

  const handleAiModelCloseModal = (_event) => {
    setShowAiModelForm(false);
  };

  // Source Performance

  const handleSourcePerformanceSubmit = async (sourcePerformance) => {
    const data = new FormData();
    data.append("song_title", sourcePerformance.songTitle);
    data.append("vocal_performer", sourcePerformance.vocalPerformer);
    data.append("description", sourcePerformance.description);
    data.append("file", sourcePerformance.file);
    data.append("project_uuid", props.project_uuid);

    const options = {
      method: "POST",
      body: data
    };

    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //   },
    // };

    dispatch({ type: "loading", val: true });

    AuthService.fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/source_performances`,
      options,
      {}
    )
    .then(
      async (result) => {
        await fetchSourcePerformances();
        AddToast("success", "Source Performance Added", null);
        return;
      },
      (error) => {
        AddToast("danger", null, error);
        return;
      }
    )
    .then(() => {
      dispatch({ type: "loading", val: false });
      setShowSourcePerformanceForm(false);
    })
  };

  const handleSourcePeformanceShowModal = (event) => {
    event.preventDefault();

    setShowSourcePerformanceForm(true);
  };

  const handleSourcePerformanceCloseModal = (_event) => {
    setShowSourcePerformanceForm(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="inference-form-component text-center">
        <Form onSubmit={handleInferenceSubmit}>

          {/* Source Performances */}

          <div className="instructions-text text-start">
            1. Select a Source Performance
          </div>

          <table className="table tbl-striped table-sm">
            <thead>
              <tr>
                <th className="checkbox-col"></th>
                <th>Song Title</th>
                <th>Vocal Performer</th>
                <th>Description</th>
                <th>Filename</th>
                <th>Uploaded</th>
                <th>
                  <div className="icon-button" onClick={handleSourcePeformanceShowModal}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                    />
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {
                sourcePerformances &&
                sourcePerformances.map(sp =>
                  <tr key={sp.uuid}>
                    <td>
                      <Form.Check
                        checked={sourcePerformance === sp.uuid}
                        onChange={() => setSourcePerformance(sp.uuid)}
                      />
                    </td>
                    <td>{sp.song_title}</td>
                    <td>{sp.vocal_performer}</td>
                    <td>{sp.description}</td>
                    <td>{sp.original_file_name}</td>
                    <td>{dateLocalDisplay(sp.created_at)}</td>
                    <td></td>
                  </tr>
                )
              }
            </tbody>
          </table>

          <br/>
          <br/>

          {/* AI Models */}

          <div className="instructions-text text-start">
            2. Select a Model
          </div>

          <table className="table tbl-striped table-sm">
            <thead>
              <tr>
                <th className="checkbox-col"></th>
                <th>Artist</th>
                <th>Description</th>
                <th>Sample Rate (kHz)</th>
                <th>Pitch Guidance</th>
                <th>Noise Filter</th>
                <th>Crepe Hop Length</th>
                <th>Epochs</th>
                <th>Technology</th>
                <th>Pitch Detect</th>
                <th>Uploaded</th>
                <th>
                  <div className="icon-button" onClick={handleAiModelShowModal}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                    />
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {
                aiModels &&
                [...aiModels].sort(
                  (a, b) => a.artist > b.artist ? 1 : -1
                ).map(aimod =>
                  <tr key={aimod.uuid}>
                    <td>
                      <Form.Check
                        checked={aiModel === aimod.uuid}
                        onChange={() => setAiModel(aimod.uuid)}
                      />
                    </td>
                    <td>{aimod.artist}</td>
                    <td>{aimod.description}</td>
                    <td>{aimod.sample_rate_k}</td>
                    <td>
                      {
                        aimod.pitch_guidance == null && <>?</>
                      }
                      {
                          aimod.pitch_guidance === true &&
                          <FontAwesomeIcon
                            icon={faCheck}
                            size="lg"
                          />
                      }
                    </td>
                    <td>
                      {
                        aimod.use_noise_filter == null && <>?</>
                      }
                      {
                          aimod.use_noise_filter === true &&
                          <FontAwesomeIcon
                            icon={faCheck}
                            size="lg"
                          />
                      }
                    </td>
                    <td>{aimod.crepe_hop_length}</td>
                    <td>{aimod.epochs}</td>
                    <td>{aimod.technology}</td>
                    <td>{f0MethodForValue(aimod.f0)}</td>
                    <td>{dateLocalDisplay(aimod.created_at)}</td>
                    <td></td>
                  </tr>
                )
              }
            </tbody>
          </table>

          <br/>
          <br/>

          {/* Parameters */}

          <div className="instructions-text text-start">
            3. Select Inference Parameters
            <span className="icon-button ms-3" onClick={() => setShowDescription(!showDescription)}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                />
            </span>
          </div>

          <div>
            <div className="row">
              <div className="col-12 col-lg-6 input-table">
                <TextInput
                  id="transposition"
                  label="Pitch Shift"
                  value={transposition}
                  onChange={(event) => { setTransposition(event.target.value) }}
                  description="The number of semitones to adjust Pitch Detection. Values: [-12..12]"
                  showDescription={showDescription}
                />
                <MultiSelectInput
                  id="f0Method"
                  label="AI Pitch Algorithm"
                  value={f0Method}
                  onChange={(event) => { setF0Method(event); }}
                  options={f0Methods}
                  description="Pitch detection algorithm."
                  showDescription={showDescription}
                />
                <TextInput
                  id="crepeHopLength"
                  label="Crepe Hop Length"
                  value={crepeHopLength}
                  onChange={(event) => { setCrepeHopLength(event.target.value) }}
                  description="The distance (in samples) between adjacent short-time windows in the Crepe algorithm. Values (0..?]"
                  showDescription={showDescription}
                  disabled={!dictValueChosen(f0Method, "mangio-crepe")}
                  optional={!dictValueChosen(f0Method, "mangio-crepe")}
                />
                <TextInput
                  id="harvestMedianFilterRadius"
                  label="Harvest Median Filter Radius"
                  value={harvestMedianFilterRadius}
                  onChange={(event) => { setHarvestMedianFilterRadius(event.target.value) }}
                  description="Apply a median filter to harvest output. Values: [0..7]"
                  showDescription={showDescription}
                  disabled={!dictValueChosen(f0Method, "harvest")}
                  optional={!dictValueChosen(f0Method, "harvest")}
                />
              </div>
              <div className="col-12 col-lg-6 input-table">
                <TextInput
                  id="mixVolumeEnvelope"
                  label="Mix Volume Envelope"
                  value={mixVolumeEnvelope}
                  onChange={(event) => { setMixVolumeEnvelope(event.target.value) }}
                  description="Control how much to use the original vocal's loudness (0) or a fixed loudness (1).  Values: [0,1]"
                  showDescription={showDescription}
                />
                <TextInput
                  id="featureIndexRatio"
                  label="Timbre Mix"
                  value={featureIndexRatio}
                  onChange={(event) => { setFeatureIndexRatio(event.target.value) }}
                  description="Used to reduce/resolve the timbre leakage problem.  Set closer to zero to protect source tones, set closer to 1 to protect training tones. Values: [0,1]"
                  showDescription={showDescription}
                />
                <TextInput
                  id="description"
                  label="Description"
                  value={description}
                  onChange={(event) => { setDescription(event.target.value) }}
                  optional={true}
                  showDescription={showDescription}
                />
              </div>
            </div>
          </div>

          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Advanced Parameters</Accordion.Header>
              <Accordion.Body>

              <div>
                <div className="row">
                  <div className="col-12 col-lg-6 input-table">
                    <MultiSelectInput
                      id="formantShifting"
                      label="Formant Shifting"
                      value={formantShifting}
                      onChange={(event) => { setFormantShifting(event) }}
                      options={booleans}
                      description="Whether to formant shift the inference audio before conversion.  If set to false, you can ignore setting the quefrency and timbre values for formanting."
                      showDescription={showDescription}
                    />
                    <TextInput
                      id="quefrency"
                      label="Quefrency"
                      value={quefrency}
                      onChange={(event) => { setQuefrency(event.target.value) }}
                      description="Formant lifter quefrency in milliseconds."
                      showDescription={showDescription}
                      disabled={!dictValueChosen(formantShifting, "true")}
                      optional={!dictValueChosen(formantShifting, "true")}
                    />
                    <TextInput
                      id="timbre"
                      label="Timbre"
                      value={timbre}
                      onChange={(event) => { setTimbre(event.target.value) }}
                      description="Fractional timbre shifting factor related to -q."
                      showDescription={showDescription}
                      disabled={!dictValueChosen(formantShifting, "true")}
                      optional={!dictValueChosen(formantShifting, "true")}
                    />
                  </div>
                  <div className="col-12 col-lg-6 input-table">
                    <TextInput
                      id="voicelessConsonantProtection"
                      label="Voiceless Consonant Protection"
                      value={voicelessConsonantProtection}
                      onChange={(event) => { setVoicelessConsonantProtection(event.target.value) }}
                      description="Control how much of the original vocal's breath and voiceless consonants to leave in the AI vocals. Smaller number = more protection. 0.50 means no protection. Values: [0,0.5]"
                      showDescription={showDescription}
                    />
                    <MultiSelectInput
                      id="useNoiseFilter"
                      label="Use Noise Filter"
                      value={useNoiseFilter}
                      onChange={(event) => { setUseNoiseFilter(event) }}
                      options={booleans}
                      showDescription={showDescription}
                    />
                  </div>
                </div>
              </div>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="required-field-text">
            <span className="asterisk">*</span> Required Field
          </div>

          <FormGroup controlId="buttons">
            <Button
              disabled={!validateInferenceForm() || loading}
              type="submit"
            >
              Submit
            </Button>
            { props.cancelForm &&
              <Button
                className="btn-secondary"
                onClick={props.cancelForm}
              >
                Cancel
              </Button>
            }
          </FormGroup>

        </Form>
      </div>

      {/* Modals */}

      <Modal show={showSourcePerformanceForm} modalClosed={handleSourcePerformanceCloseModal} >
        <h4 className="text-center">Add Source Performance</h4>
        <hr/>

        {
          showSourcePerformanceForm &&
          <SourcePerformanceForm
            cancelForm={handleSourcePerformanceCloseModal}
            handleSubmit={handleSourcePerformanceSubmit}
          />
        }
      </Modal>

      <Modal show={showAiModelForm} modalClosed={handleAiModelCloseModal} >
        <h4 className="text-center">Add AI Model</h4>
        <hr/>

        { showAiModelForm &&
          <AiModelForm
            cancelForm={handleAiModelCloseModal}
            handleSubmit={handleAiModelSubmit}
          />
        }
      </Modal>
    </>
  );
};


InferenceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  project_uuid: PropTypes.string.isRequired
}


export default InferenceForm;