import React, { useContext } from "react";

import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";
import Spinner from "components/UI/Spinner";
import { ToasterContext } from "contexts/ToasterContext";
import UserForm from "components/Users/UserForm"
import withAuth from "withAuth";

import "./UserProfile.scss";

const UserProfile = (props) => {
  const { state: { loading }, dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const { user: { is_admin, username, uuid } } = props;

  const handleSubmit = (user) => {
    dispatch({ type: "loading", val: true });

    AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/update_profile`, {
      method: "PATCH",
      body: JSON.stringify({
        first_name: user.firstName,
        last_name: user.lastName
      })
    })
    .then(
      (result) => {
        dispatch({ type: "loading", val: false });
        dispatch({ type: "user", val: result.user });
        AddToast("success", "User Profile Updated", null);
      },
      (error) => {
        dispatch({ type: "loading", val: false });
        AddToast("danger", null, error);
      }
    );
  }

  return (
    <div className="user-profile-component text-center">
      <Spinner show={loading} />

      <h2>{ username }</h2>
        { is_admin &&
          <small>Admin</small>
        }
      <hr/>

      <UserForm
        editStatus={false}
        handleSubmit={handleSubmit}
        loading={loading}
        uuid={uuid}
       />

    </div>
  );
};

export default withAuth(UserProfile);
