import React, { useContext, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";

import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";
import { isValidEmail } from "lib/Utils";
import Spinner from "components/UI/Spinner";
import { ToasterContext } from "contexts/ToasterContext";

import "./ForgotPassword.scss";

const ForgotPassword = (props) => {
  const { state: { loading }, dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const navigate = useNavigate();

  // Form Values
  const [email, setEmail] = useState("");

  const validateForm = () => {
    return isValidEmail(email);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({ type: "loading", val: true });

    AuthService.fetchNotLoggedIn(`${process.env.REACT_APP_API_ENDPOINT}/user/forgot_password`, {
      method: "POST",
      body: JSON.stringify({
        email: email
      })
    })
    .then((result) => {
      dispatch({ type: "loading", val: false });
      AddToast("success", "You will receive an email shortly", "With directions to reset your password.");
      navigate("/login");
    })
    .catch((fetchError) => {
      dispatch({ type: "loading", val: false });
      AddToast("danger", null, fetchError.message);
    })
  };

  return (
    <div className="forgot-password-component text-center">
      <Spinner show={loading} />

      <h2>Forgot Password</h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-group" controlId="email">
          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={handleChange}
            placeholder="email"
          />
        </Form.Group>
        <Form.Group className="form-group" controlId="buttons">
          <Button
            disabled={!validateForm() || loading}
            type="submit"
          >
            Reset
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default ForgotPassword;
