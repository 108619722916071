import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import AuthService from './services/AuthService';

export default function withAuth(AuthComponent) {

  return (props) => {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);

    useEffect(() => {
      if (!AuthService.loggedIn()) {
        navigate('/login');
      } else {
        try {
          setUser(AuthService.getUser());
        } catch (error) {
          AuthService.logout();
          navigate('/login');
        }
      }
    }, [navigate]);

    if (user) {
      return (
        <AuthComponent
          {...props}
          user={user}
        />
      )
    } else {
      return null;
    }
  }
}
