import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ApiService from "services/ApiService";
import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";
import { dateLocalDisplay } from "lib/Utils";
import InferenceRow from "components/Project/InferenceRow";
import Spinner from "components/UI/Spinner";
import { ToasterContext } from "contexts/ToasterContext";
import InferenceForm from "components/Project/InferenceForm"
import withAuth from "withAuth";

import "./Project.scss";


const Project = (props) => {
  const { uuid } = useParams();

  const { state: { loading }, dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const [inferences, setInferences] = useState(null);
  const [project, setProject] = useState(null);

  // Data Fetching

  const fetchData = async () => {
    dispatch({ type: "loading", val: true });
    {
      const data = await ApiService.getProject(uuid);
      setProject(data);
    }
    {
      const data = await ApiService.getInferencesForProject(uuid);
      setInferences(data);
    }
    dispatch({ type: "loading", val: false });
  };

  // Inference Action Handling

  const handleArchiveInference = async (inference) => {
    dispatch({ type: "loading", val: true });

    AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/inferences/${inference.uuid}/update_visibility_status`, {
      method: "PATCH",
      body: JSON.stringify({
        status: "archived",
      })
    })
    .then(
      (_result) => {
        dispatch({ type: "loading", val: false });
        fetchData();
        AddToast("success", "Inference Archived", null);
      },
      (error) => {
        dispatch({ type: "loading", val: false });
        AddToast("danger", null, error);
      }
    )
  };

  const handleCancelInference = async (inference) => {
    dispatch({ type: "loading", val: true });

    AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/inferences/${inference.uuid}/update_processing_status`, {
      method: "PATCH",
      body: JSON.stringify({
        status: "canceled",
      })
    })
    .then(
      (_result) => {
        dispatch({ type: "loading", val: false });
        fetchData();
        AddToast("success", "Inference Canceled", null);
      },
      (error) => {
        dispatch({ type: "loading", val: false });
        AddToast("danger", null, error);
      }
    )
  };

  const handleSubmitInference = (inference) => {
    dispatch({ type: "loading", val: true });

    AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/projects/${uuid}/inferences`, {
      method: "POST",
      body: JSON.stringify({
        transposition: inference.transposition,
        f0_method: inference.f0Method,
        crepe_hop_length: inference.crepeHopLength,
        harvest_median_filter_radius: inference.harvestMedianFilterRadius,
        mix_volume_envelope: inference.mixVolumeEnvelope,
        feature_index_ratio: inference.featureIndexRatio,
        voiceless_consonant_protection: inference.voicelessConsonantProtection,
        formant_shifting: inference.formantShifting,
        quefrency: inference.quefrency,
        timbre: inference.timbre,
        use_noise_filter: inference.useNoiseFilter,
        description: inference.description,
        ai_model_uuid: inference.aiModelUuid,
        source_performance_uuid: inference.sourcePerformanceUuid
      })
    })
    .then(
      (_result) => {
        dispatch({ type: "loading", val: false });
        fetchData();
        AddToast("success", "Inference(s) Added", null);
      },
      (error) => {
        dispatch({ type: "loading", val: false });
        AddToast("danger", null, error);
      }
    )
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inferenceSortKey = (inference) => {
    // const key =
    //   `${inference.source_performance.song_title}` +
    //   `${inference.source_performance.vocal_performer}` +
    //   `${inference.source_performance.description}` +
    //   `${inference.source_performance.created_at}` +
    //   `${inference.ai_model.artist}` +
    //   `${inference.ai_model.description}` +
    //   `${inference.ai_model.f0}` +
    //   `${inference.ai_model.created_at}`;

    const key = `${inference.created_at}`;

    return key;
  };

  return (
    <>
      <div className="project-component">
        <Spinner show={loading} />

        <div className="text-center">

          <div className="project-header">
            <div className="project-name">
              Project: {project?.name}
            </div>
            <div className="project-created">
              created by: {project?.created_by}, {dateLocalDisplay(project?.created_at)}
            </div>
            <hr/>
            <div className="project-description">
              {project?.description}
            </div>
          </div>

          <br/>

          <InferenceForm
            handleSubmit={handleSubmitInference}
            project_uuid={uuid}
          />

          <br/>
          <hr/>
          <br/>

          <table className="table tbl-striped table-sm">
            <thead>
              <tr>
                <th>Source<br/>Performance</th>
                <th>AI Model</th>
                <th>Description</th>
                <th>Transposition</th>
                <th></th>
                <th></th>
                <th>Formant<br/>Shifting</th>
                <th>Noise Filter</th>
                <th>Status</th>
                <th>Created</th>
                <th></th>
              </tr>
            </thead>

            <tbody className="double-rows">
            { inferences &&
              [...inferences].sort(
                (a, b) => inferenceSortKey(a) < inferenceSortKey(b) ? 1 : -1
              ).map(inference =>
                <InferenceRow
                  handleArchiveInference={handleArchiveInference}
                  handleCancelInference={handleCancelInference}
                  inference={inference}
                  key={inference.uuid}
                />
              )
            }
            </tbody>
          </table>

        </div>
      </div>
    </>
  );
};

export default withAuth(Project);
