import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons"

import "./About.scss";

import img1 from '../../assets/about14.png';
import img2 from '../../assets/about15.png';
import img3 from '../../assets/about16.png';

const About = (props) => {
  return (
    <>
      <div className="about-component">
        <div>
          <div>
            <img
                src={img1}
                alt="Pure-Info-1"
              />
          </div>
          <div className="music-icon">
            <FontAwesomeIcon icon={faMusic} />
          </div>
          <div>
            <img
                src={img2}
                alt="Pure-Info-2"
              />
          </div>
        </div>
        <div className="music-icon">
            <FontAwesomeIcon icon={faMusic} />
          </div>
        <div>
          <div>
            <img
                src={img3}
                alt="Pure-Info-3"
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
