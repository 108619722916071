import React, { useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";

import { FileInput, TextInput } from "components/UI/Forms"

import "./SourcePerformanceForm.scss";

const SourcePerformanceForm = (props) => {
  const [songTitle, setSongTitle] = useState("");
  const [vocalPerformer, setVocalPerformer] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    let sourcePerformance = {
      songTitle: songTitle,
      vocalPerformer: vocalPerformer,
      description: description,
      file: file
    }

    props.handleSubmit(sourcePerformance);
  };

  const validateForm = () => {
    return  songTitle &&
            vocalPerformer &&
            file;
  };

  return (
    <div className="source-performance-form-component text-center">
      <Form onSubmit={handleSubmit}>
        <div className="input-table">
          <TextInput
            id="songTitle"
            label="Song Title"
            value={songTitle}
            onChange={(event) => { setSongTitle(event.target.value) }}
          />
          <TextInput
            id="vocalPerformer"
            label="Vocal Performer"
            value={vocalPerformer}
            onChange={(event) => { setVocalPerformer(event.target.value) }}
          />
          <TextInput
            id="description"
            label="Description"
            value={description}
            optional={true}
            onChange={(event) => { setDescription(event.target.value) }}
          />
          <FileInput
            id="file"
            label="Source Performance File"
            value={file}
            onChange={(event) => { setFile(event.target.files[0]) }}
          />
        </div>

        <div className="required-field-text">
          <span className="asterisk">*</span> Required Field
        </div>

        <FormGroup controlId="buttons">
          <Button
            disabled={!validateForm() || props.loading}
            type="submit"
          >
            Submit
          </Button>
          { props.cancelForm &&
            <Button
              className="btn-secondary"
              onClick={props.cancelForm}
            >
              Cancel
            </Button>
          }
        </FormGroup>

      </Form>

    </div>
  );
};

export default SourcePerformanceForm;
