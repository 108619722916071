import React, { useContext, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from "react-router-dom";


import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";
import { isValidEmail } from "lib/Utils";
import Spinner from "components/UI/Spinner";
import { ToasterContext } from "contexts/ToasterContext";

import "./Login.scss";

const Login = (props) => {
  const { state: { loading }, dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const navigate = useNavigate();

  // Form Values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const validateForm = () => {
    return isValidEmail(email) && password.length >= 6;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({ type: "loading", val: true });

    AuthService.login(email, password)
      .then(_result => {
        dispatch({ type: "loading", val: false });
        navigate('/');
        dispatch({ type: "loggedIn", val: AuthService.loggedIn() });
        dispatch({ type: "user", val: AuthService.getUser() });
      })
      .catch(error => {
        dispatch({ type: "loading", val: false });
        AddToast("danger", null, error.message);
      })
  };

  useEffect(() => {
    if (AuthService.loggedIn())
      navigate('/');
  });

  return (
    <div className="login-component text-center">
      <Spinner show={loading} />

      <h2>Login</h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-group" controlId="email">
          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="email"
          />
        </Form.Group>
        <Form.Group className="form-group" controlId="password">
          <Form.Control
            value={password}
            onChange={handlePasswordChange}
            type="password"
            placeholder="password"
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="form-group" controlId="buttons">
          <Button
            disabled={!validateForm() || loading}
            type="submit"
          >
            Login
          </Button>
        </Form.Group>
        <Link to="/forgot-password" className="forgot-password">Forgot your password?</Link>
        <br/>
        <Link to="/register" className="forgot-password">Need to sign up?</Link>
      </Form>
    </div>
  );
};

export default Login;
