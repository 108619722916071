import React, { useContext, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from "react-router-dom";

import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";
import { isValidEmail } from "lib/Utils";
import Spinner from "components/UI/Spinner";
import { ToasterContext } from "contexts/ToasterContext";

import "./Register.scss";

const Register = (props) => {
  const { state: { loading }, dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateForm = () => {
    return isValidEmail(email) && password.length >= 6;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch({ type: "loading", val: true });

    await AuthService.fetchNotLoggedIn(`${process.env.REACT_APP_API_ENDPOINT}/user/register`, {
      method: "POST",
      body: JSON.stringify({
        username: email,
        password: password
      })
    })
    .then((result) => {
      console.log(result);
      dispatch({ type: "loading", val: false });
      AddToast("success", null, result);
      navigate("/login");
    })
    .catch((fetchError) => {
      dispatch({ type: "loading", val: false });
      AddToast("danger", null, fetchError.message);
    })
  };

  useEffect(() => {
    if (AuthService.loggedIn())
      navigate('/');
  });

  return (
    <div className="register-component text-center">
      <Spinner show={loading} />

      <h2>Register</h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-group" controlId="email">
          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="email"
          />
        </Form.Group>
        <Form.Group className="form-group" controlId="password">
          <Form.Control
            value={password}
            onChange={handlePasswordChange}
            type="password"
            placeholder="password"
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="form-group" controlId="buttons">
          <Button
            disabled={!validateForm()}
            type="submit"
          >
            Register
          </Button>
        </Form.Group>
        <Link to="/login" className="forgot-password">Already have an account?</Link>
      </Form>
    </div>
  );
};

export default Register;
