import { jwtDecode } from "jwt-decode";

// import axios from "axios";
import FetchError from "lib/FetchError";

export default class AuthService {
  // LOGIN

  static login = async (email, password) => {
    // Get a token from api server using the fetch api

    const data = new FormData();
    data.append("username", email);
    data.append("password", password);

    // If using JSON post instead of FORM post
    // const headers = AuthService.json_headers();
    // body: JSON.stringify({ username: email, password })

    const options = {
      method: "POST",
      body: data
    };

    // axios.post(
    //   `${process.env.REACT_APP_API_ENDPOINT}/auth/login`,
    //   data
    // )
    // .then(function (response) {
    //   console.log(response);
    //   if (response.data.token) {
    //     AuthService._setToken(response.data.token);
    //   }
    // })
    // .catch(function (error) {
    //   console.log(error, "error");
    // });
  
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/login`, options);
    const json = await response.json();

    if (response.ok) {
      AuthService._setToken(json.access_token);
      AuthService._setUser(json.user);
    } else {
      if (json.detail) {
        throw Error(json.detail);
      } else if (response.status === 401) {
        throw Error("Unauthorized");
      } else if (response.status) {
        throw Error(`Error code: ${response.status}`);
      } else {
        throw Error("Unknown Error");
      }
    }
  };

  static loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = AuthService._getToken();
    return !!token && !AuthService._isTokenExpired(token);
  };

  // LOGOUT

  static logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
  };

  // TOKEN

  static _isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
        return true;
      } else
        return false;
    } catch (error) {
      return false;
    }
  };

  static _setToken = (token) => {
    localStorage.setItem("id_token", token);
  };

  static _getToken = () => {
    return localStorage.getItem("id_token");
  };

  static getDecodedToken = () =>  {
    // Using jwt-decode npm package to decode the token
    return jwtDecode(AuthService._getToken());
  };

  // USER

  static _setUser = (user) => {
    if (user != null) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  };

  static getUser = () => {
    const userObj = localStorage.getItem("user");

    if (userObj !== null && userObj !== undefined) {
      return JSON.parse(userObj);
    } else {
      return null;
    }
  };

  // FETCH

  static fetch = (url, options, headers=AuthService.json_headers()) => {
    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx

    if (AuthService.loggedIn()) {
      headers["Authorization"] = "Bearer " + AuthService._getToken();
      return AuthService._fetch(url, headers, options);
    } else {
      console.log("Not Logged In");
      return;
    }
  };

  static _fetch = async (url, headers, options) => {
    const content = await fetch(url, {headers, ...options});
    const result = await AuthService._checkStatus(content);

    if (headers["Accept"] === "application/json") {
      return await result.json();
    } else {
      return result;
    }
  };

  static _checkStatus = async (response) => {
    // Raises an error in case response status is not a success

    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response
    } else if ([401, 403].indexOf(response.status) !== -1) {
      // 401: Unauthorized
      // 403: Forbidden
      AuthService.logout();
      window.location.reload(true);
    } else {
      const errorContent = await response.json();
      console.log(errorContent);
      if (errorContent.detail) {
        throw errorContent.detail;
      } else {
        throw response.statusText;
      }
    }
  };

  // FETCH - NOT LOGGED IN

  static fetchNotLoggedIn = async (url, options) => {
    const headers = AuthService.json_headers();

    const response = await fetch(url, {
      headers,
      ...options
    });

    const json = await response.json();

    if (response.ok) {
      return json.success;
    } else {
      if (json.detail) {
        throw new FetchError(response.status, json.detail);
      } else {
        throw new FetchError(response.status, "Unknown Error");
      }
    }
  };

  // HEADERS

  static json_headers = () => {
    return {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
  };

  static csv_headers = () => {
    return {
      "Accept": "txt/csv",
      "Content-Type": "application/json"
    }
  };
}
