import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ApiService from "services/ApiService";
import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";
import { dateLocalDisplay } from "lib/Utils";
import ProjectForm from "components/Projects/ProjectForm";
import Modal from "components/UI/Modal"
import { ToasterContext } from "contexts/ToasterContext";
import withAuth from "withAuth";

import "./Projects.scss";


const Projects = () => {
  const { dispatch } = useContext(ContextOne);
  const { AddToast } = useContext(ToasterContext);

  const [projects, setProjects] = useState(null);

  const [showProjectForm, setShowProjectForm] = useState(false);

  // Routing

  let navigate = useNavigate();

  const routeToProject = (uuid) => {
    let path = `/projects/${uuid}`;
    navigate(path);
  }

  // Data Fetching

  const fetchProjects = async () => {
    const data = await ApiService.getProjects();
    setProjects(data);
  };

  const fetchData = async () => {
    dispatch({ type: "loading", val: true });

    await fetchProjects();

    dispatch({ type: "loading", val: false });
  };

  // Source Performance

  const handleProjectSubmit = async (project) => {
    dispatch({ type: "loading", val: true });

    AuthService.fetch(`${process.env.REACT_APP_API_ENDPOINT}/projects`, {
      method: "POST",
      body: JSON.stringify({
        name: project.name,
        description: project.description
      })
    })
    .then(
      async (result) => {
        await fetchProjects();
        AddToast("success", "Project Added", null);
        return;
      },
      (error) => {
        AddToast("danger", null, error);
        return;
      }
    )
    .then(() => {
      dispatch({ type: "loading", val: false });
      setShowProjectForm(false);
    })
  };

  const handleProjectShowModal = (event) => {
    event.preventDefault();

    setShowProjectForm(true);
  };

  const handleProjectCloseModal = (_event) => {
    setShowProjectForm(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="projects-component text-center">

        <div className="projects-header">
            <div className="title">
              Projects
            </div>
          </div>

        <table className="table tbl-striped table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Created By</th>
              <th>Created Date</th>
              <th>
                <div className="icon-button" onClick={handleProjectShowModal}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                  />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {
              projects &&
              projects.map(p =>
                <tr key={p.uuid} onClick={() => routeToProject(p.uuid)}>
                  <td>{p.name}</td>
                  <td>{p.description}</td>
                  <td>{p.created_by}</td>
                  <td>{dateLocalDisplay(p.created_at)}</td>
                  <td></td>
                </tr>
              )
            }
          </tbody>
        </table>

      </div>

      <Modal show={showProjectForm} modalClosed={handleProjectCloseModal} >
        <h4 className="text-center">Create Project</h4>
        <hr/>

        {
          showProjectForm &&
          <ProjectForm
            cancelForm={handleProjectCloseModal}
            handleSubmit={handleProjectSubmit}
          />
        }
      </Modal>
    </>
  );
};

export default withAuth(Projects);
