import React from "react"

import AuthService from "../services/AuthService";


let ContextOne = React.createContext();

let initialState = {
  loading: false,
  loggedIn: AuthService.loggedIn(),
  user: AuthService.getUser(),
};

let reducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: action.val
      };
    case "loggedIn":
      return {
        ...state,
        loggedIn: action.val
      };
    case "reset":
      return initialState;
    case "user":
      return {
        ...state,
        user: action.val,
      };
    default:
      return { ...state };
  }
};

function ContextOneProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <ContextOne.Provider value={value}>{props.children}</ContextOne.Provider>
  );
}

export { ContextOne, ContextOneProvider };
