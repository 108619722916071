import React, { useContext, useEffect, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";

import ApiService from "services/ApiService";
import { ContextOne } from "contexts/ContextOne";
import { isValidEmail } from "lib/Utils";
import { TextInput } from "components/UI/Forms"
import Spinner from "components/UI/Spinner";

import "./UserForm.scss";

const UserForm = (props) => {
  const { state: { loading }, dispatch } = useContext(ContextOne);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");


  const handleSubmit = (event) => {
    event.preventDefault();

    let user = {
      uuid: props.uuid,
      username: username,
      email: email,
      firstName: firstName,
      lastName: lastName
    }

    props.handleSubmit(user);
  };

  const validateForm = () => {
    return firstName &&
           lastName &&
           isValidEmail(email);
  };

  useEffect(() => {
    if (props.uuid) {
      async function getUserWrapper(uuid) {
        dispatch({ type: "loading", val: true });

        const user = await ApiService.getUser(uuid);
        setUsername(user.username);
        setEmail(user.email);
        setFirstName(user.first_name);
        setLastName(user.last_name);

        dispatch({ type: "loading", val: false });
      }

      getUserWrapper(props.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uuid]);

  return (
    <div className="user-form-component text-center">
      <Spinner show={loading} />

      <Form onSubmit={handleSubmit}>

        <div className="input-table">
          <TextInput
            id="firstName"
            label="First Name"
            value={firstName}
            onChange={(event) => { setFirstName(event.target.value) }}
          />
          <TextInput
            id="lastName"
            label="Last Name"
            value={lastName}
            onChange={(event) => { setLastName(event.target.value) }}
          />
          {/* <TextInput
            id="email"
            label="Email"
            value={email}
            onChange={(event) => { setEmail(event.target.value) }}
          /> */}
        </div>

        <div className="required-field-text">
          <span className="asterisk">*</span> Required Field
        </div>

        <FormGroup controlId="buttons">
          <Button
            disabled={!validateForm() || props.loading}
            type="submit"
          >
            Submit
          </Button>
          { props.cancelForm &&
            <Button
              className="btn-secondary"
              onClick={props.cancelForm}
            >
              Cancel
            </Button>
          }
        </FormGroup>

      </Form>

    </div>
  );
};

export default UserForm;
