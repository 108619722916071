import { useRef, useState } from 'react';

// import components
import Controls from './Controls';
import DisplayTrack from './DisplayTrack';
import ProgressBar from './ProgressBar';
import Waveform from './Waveform';

import './index.scss';
import './customize-progress-bar.scss';

const AudioPlayer = ({
  fileLocation
}) => {
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef();
  const progressBarRef = useRef();
  const waveformRef = useRef();

  return (
    <div className="audio-player-component">
      <div className="inner">
        { false &&
          <DisplayTrack
            {...{
              fileLocation,
              audioRef,
              setDuration,
              progressBarRef,
            }}
          />
        }
        <Waveform {...{
            fileLocation,
            setDuration,
            waveformRef,
            progressBarRef
          }} />
        <Controls
          {...{
            audioRef,
            progressBarRef,
            duration,
            setTimeProgress,
            waveformRef
          }}
        />
        <ProgressBar
          {...{
            progressBarRef,
            audioRef,
            timeProgress,
            duration,
            waveformRef
          }}
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
