// https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
export const groupBy = (data, key) => {
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce(function(storage, item) {
    // get the first instance of the key by which we're grouping
    var group = item[key];

    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || [];

    // add this item to its group within `storage`
    storage[group].push(item);

    // return the updated storage to the reduce function, which will then loop through the next
    return storage;
  }, {}); // {} is the initial value of the storage
};

export const isValidEmail = (str) => {
   // eslint-disable-next-line no-useless-escape
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(str));
};

export const queryString = (params) =>
  Object.keys(params).map(key => key + '=' + params[key]).join('&');

export const removeEmpty = (obj) =>
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeEmpty(val)
    else if (val == null || val === "") delete obj[key]
});

export const sum = (obj_arr, key) => {
  return obj_arr.reduce((a, b) => a + (b[key] || 0), 0);
};

export const titleize = (str) => {
  if (!str.split) return str;

  const _titleizeWord = function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }, result = [];

  str.split(/_| /).forEach(function(w) {
      result.push(_titleizeWord(w));
  });
  return result.join(" ");
};

export const intToString = (val, strLen) => {
  const valStr = val.toString();
  const padded = valStr.padStart(strLen, "0");
  return padded;
};

export const dateLocalDisplay = (dateStr) => {
  var date = new Date(dateStr)
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const d = new Date(date.getTime() - userTimezoneOffset);

  let amPm = "AM";

  let hourVal = d.getHours();
  if (hourVal > 12) {
    hourVal -= 12;
    amPm = "PM";
  }

  const month = intToString(d.getMonth() + 1, 2);
  const day = intToString(d.getDate(), 2);
  const year = d.getFullYear();
  const hour = intToString(hourVal, 2);
  const minute = intToString(d.getMinutes(), 2);

  const formattedTime = `${year}-${month}-${day} ${hour}:${minute} ${amPm}`;

  return formattedTime;
};

export const dictValueChosen = (dict, val) => {
  const vals = dict.map(getDictValues);
  return vals.includes(val);
}

export const getDictValues = (dict) => {
  return dict.value;
}
