import React, { useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";

import { FileInput, TextInput } from "components/UI/Forms"

import "./AiModelForm.scss";

const AiModelForm = (props) => {
  const [artist, setArtist] = useState("");
  const [description, setDescription] = useState("");

  const [indexFile, setIndexFile] = useState("");
  const [pthFile, setPthFile] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    let aiModel = {
      artist: artist,
      description: description,
      indexFile: indexFile,
      pthFile: pthFile
    }

    props.handleSubmit(aiModel);
  };

  const validateForm = () => {
    return  artist &&
            pthFile;
  };

  return (
    <div className="ai-model-form-component text-center">
      <Form onSubmit={handleSubmit}>
        <div className="input-table">
          <TextInput
            id="artist"
            label="Artist"
            value={artist}
            onChange={(event) => { setArtist(event.target.value) }}
          />
          <TextInput
            id="description"
            label="Description"
            value={description}
            optional={true}
            onChange={(event) => { setDescription(event.target.value) }}
          />
          <FileInput
            id="pthFile"
            label="PTH File"
            value={pthFile}
            onChange={(event) => { setPthFile(event.target.files[0]) }}
          />
          <FileInput
            id="indexFile"
            label="Index File"
            value={indexFile}
            optional={true}
            onChange={(event) => { setIndexFile(event.target.files[0]) }}
          />
        </div>

        <div className="required-field-text">
          <span className="asterisk">*</span> Required Field
        </div>

        <FormGroup controlId="buttons">
          <Button
            disabled={!validateForm() || props.loading}
            type="submit"
          >
            Submit
          </Button>
          { props.cancelForm &&
            <Button
              className="btn-secondary"
              onClick={props.cancelForm}
            >
              Cancel
            </Button>
          }
        </FormGroup>

      </Form>

    </div>
  );
};

export default AiModelForm;
