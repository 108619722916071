import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import AuthService from "services/AuthService";
import { ContextOne } from "contexts/ContextOne";

import "./Header.scss";

const Header = (props) => {
  const { state, dispatch } = useContext(ContextOne);
  const { loggedIn, user } = state;

  const navigate = useNavigate();

  const handleLogout = (event) => {
    AuthService.logout();
    dispatch({ type: "loggedIn", val: false });
    dispatch({ type: "user", val: false });
    navigate("/login");
  };


  return (
    <div className="header-component">

      <Navbar expand="lg">
        <Navbar.Brand>
          <Link to="/" className="company-name">
            {/* <img height="75px" alt="Pure" src={process.env.PUBLIC_URL + "/img/pure-logo-header.png"} /> */}
            PURE AI
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler"/>

        <Navbar.Collapse id="basic-navbar-nav">
          { loggedIn &&
            <Nav className="me-auto">

              { user?.is_admin && false &&
                <NavDropdown
                  title="ADMIN"
                  id="nav-dropdown-admin"
                  >
                  <NavDropdown.Item
                    eventKey="1.1"
                    as={Link}
                    to="/admin/organizations"
                    >
                    organizations
                  </NavDropdown.Item>
                </NavDropdown>
              }
            </Nav>
          }

          <Nav className="ms-auto">
            { loggedIn &&
              <div className="nav-link" role="button">
                <Link to="/projects">projects</Link>
              </div>
            }
            <div className="nav-link" role="button">
              <Link to="/about">about</Link>
            </div>
            { !loggedIn &&
              <div className="nav-link" role="button">
                <Link to="/login">login</Link>
              </div>
            }
            { loggedIn &&
              <NavDropdown
                title={
                  <FontAwesomeIcon icon={faUser} />
                }
                id="nav-dropdown-user"
                >
                <NavDropdown.Item
                  eventKey="4.1"
                  as={Link}
                  to="/user-profile"
                  >
                  User Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="4.2"
                  onClick={handleLogout}
                  >
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            }
        </Nav>
        </Navbar.Collapse>
      </Navbar>

    </div>
  );
};

export default Header;
