import React from "react";

import Backdrop from "../Backdrop"

import "./Modal.scss";

const Modal = props => {
  return (
    <>
      <Backdrop show={props.show} clicked={props.modalClosed} />
      <div
        className="modal-component"
        style={{
          transform: props.show ? 'translate(-50%, -50%)' : 'translateY(-200vh)',
          opacity : props.show ? 1 : 0
        }}
      >
        {props.children}
      </div>
      </>
  );
};

export default Modal;

// https://medium.com/@infinitypaul/reusable-modal-with-react-d61c2876028c
