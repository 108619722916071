import React from "react"

import checkIcon from '../assets/check.svg';
import errorIcon from '../assets/error.svg';
import infoIcon from '../assets/info.svg';
import warningIcon from '../assets/warning.svg';

const ToasterContext = React.createContext();

let initialState = {
  list: [],
};

let reducer = (state, action) => {
  const newId = Math.floor((Math.random() * 101) + 1);
  let toastProperties = null;

  let { type, title, description, id } = action;

  switch(type) {
    case "remove":
      return {
        ...state,
        list: state.list.filter(item => item.id !== id)
      };
    case "success":
      toastProperties = {
        id: newId,
        title: title || "Success",
        description: description || "",
        backgroundColor: "#5cb85c",
        icon: checkIcon
      }
      break;
    case "danger":
      toastProperties = {
        id: newId,
        title: title || "Error",
        description: description || "",
        backgroundColor: "#d9534f",
        icon: errorIcon
      }
      break;
    case "info":
      toastProperties = {
        id: newId,
        title: title || "Info",
        description: description || "",
        backgroundColor: "#5bc0de",
        icon: infoIcon
      }
      break;
    case "warning":
      toastProperties = {
        id: newId,
        title: title || "Warning",
        description: description || "",
        backgroundColor: "#f0ad4e",
        icon: warningIcon
      }
      break;
    default:
      return { ...state };
  }

  return { ...state, list: [...state.list, toastProperties] };
};

const ToasterContextProvider = (props) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);

  const AddToast = (type, title, description) => {
    dispatch({type: type, title: title, description: description});
  }

  const RemoveToast = (id) => {
    dispatch({type: "remove", id: id});
  }

  let value = { state, AddToast, RemoveToast };

  return (
    <ToasterContext.Provider value={value}>{props.children}</ToasterContext.Provider>
  );
}

export { ToasterContext, ToasterContextProvider };
