import React from "react"

import "./Spinner.scss";

const Spinner = (props) => {
  return (
    <>
      { props.show &&
        <div className="spinner-component">
          <svg viewBox="0 0 100 100">
            <defs>
              <filter id="shadow">
                <feDropShadow
                  dx="0"
                  dy="0"
                  stdDeviation="1.5"
                  floodColor="#007B9F"
                />
              </filter>
            </defs>
            <circle
              id="spinner"
              style={{filter: `url(#shadow)`}}
              cx="50"
              cy="50"
              r="45"
            />
          </svg>
        </div>
      }
    </>
  )
}

export default Spinner;
