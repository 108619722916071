export const booleans = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const f0Methods = [
  { value: "pm", label: "PM" }, // Pitch Mark
  { value: "harvest", label: "Harvest" },
  { value: "crepe", label: "Crepe"},
  { value: "mangio-crepe", label: "Mangio Crepe"},
  { value: "rmvpe", label: "RMVPE"}, // Robust Model for Vocal Pitch Estimation (RMVPE)
];

export const f0MethodForValue = (key) => {
  var result = f0Methods.find(f0 => {
    return f0.value === key
  });

  return result?.label;
};

export const inferenceStatuses = [
  { value: "queued", label: "Queued" },
  { value: "processing", label: "Processing" },
  { value: "complete", label: "Complete"},
  { value: "error", label: "Error" },
  { value: "canceled", label: "Canceled" },
];

export const organizationStatuses = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const userStatuses = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];
